<template>
  <div class="about">
    <About></About>
  </div>
</template>

<script>
import About from "@/components/About-Content.vue";
export default {
  name: "Home",

  components: {
    About: About
  },
  mounted() {},
  methods: {}
};
</script>
